import React, { useMemo, useCallback, useState } from "react"
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography
} from "@mui/material"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import AttachmentIcon from "@mui/icons-material/Attachment"
import DeleteIcon from "@mui/icons-material/Delete"
import { I18n, Translate } from "react-redux-i18n"
import { v4 as uuidv4 } from "uuid"
import Dropzone from "react-dropzone"
import isNil from "lodash/isNil"
import { filesize } from "filesize"

import { MAX_FILE_SIZE } from "constants.js"
import useConfirm from "hooks/useConfirm"

/**
 * @param {'warning' | 'error'} variant
 */
const getDetailsTextColor = (variant) => {
  if (variant === "error") {
    return "red"
  }

  return "inherit"
}

/**
 *
 * @param {object} props
 * @param {{fileName: string; details: string[]}} props.value
 * @param {'warning' | 'error'} props.variant
 */
const DropzoneHelperTextComponent = ({ value, variant }) => {
  return (
    <Box mb={2.25}>
      <Typography variant="h3">{value.fileName}</Typography>
      {value.details.map((text) => {
        return (
          <Box key={text} sx={{ color: getDetailsTextColor(variant) }}>
            {text}
          </Box>
        )
      })}
    </Box>
  )
}

export const DropzoneHelperText = React.memo(DropzoneHelperTextComponent)

const UploadedFile = ({ file, onDelete, onDeleteSuccess, onPreview }) => {
  const confirm = useConfirm()
  const [isDeleting, setIsDeleting] = useState(false)
  const handlePreview = useCallback(() => {
    if (onPreview) {
      onPreview(file)
    }
  }, [onPreview, file])

  return (
    <ListItem
      sx={{
        padding: "16px",
        borderRadius: "8px",
        backgroundColor: "#FAFAFA"
      }}
    >
      <ListItemIcon>
        <AttachmentIcon />
      </ListItemIcon>
      <ListItemText
        onClick={handlePreview}
        primary={
          <Stack sx={{ cursor: onPreview ? "pointer" : "interit" }}>
            <Box
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "75%"
              }}
            >
              {file.file_name ?? file.name}
            </Box>
            {!isNil(file.size) && (
              <Typography>{filesize(file.size ?? 0)}</Typography>
            )}
          </Stack>
        }
      />
      <ListItemSecondaryAction>
        <Stack direction="row" pr={2} gap={2}>
          <IconButton
            edge="end"
            aria-label={I18n.t("remove")}
            title={I18n.t("remove")}
            disabled={isDeleting}
            onClick={() =>
              confirm(I18n.t("delete.sure", { entity: file.file_name }), {
                onConfirm: async () => {
                  try {
                    setIsDeleting(true)
                    await onDelete(file)
                    if (onDeleteSuccess) {
                      await onDeleteSuccess(file)
                    }
                  } finally {
                    setIsDeleting(false)
                  }
                }
              })
            }
            size="large"
          >
            {isDeleting ? <CircularProgress size={18} /> : <DeleteIcon />}
          </IconButton>
        </Stack>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

/**
 * @param {object} props
 * @param {(file: any) => void} props.uploadAsyncFn
 * @param {object[]} props.value
 * @param {(files: object[]) => void} props.onChange
 * @param {(file: any) => void} props.onDelete
 * @param {(file: any) => void} [props.onDeleteSuccess]
 * @param {(file: any) => void} [props.onPreview]
 * @param {React.ReactNode} props.hint
 * @param {{[k: string]: string[]}} props.accept
 * @param {boolean} [props.multiple]
 * @param {boolean} [props.error]
 * @param {string} [props.helperText]
 * @param {React.ReactNode} [props.embeddedErrors]
 */
const FilesDropzoneComponent = ({
  uploadAsyncFn,
  value,
  onChange,
  onDelete,
  onDeleteSuccess,
  onPreview,
  accept,
  hint,
  disabled,
  error = false,
  helperText,
  multiple = false,
  embeddedErrors = null
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const validTypes = useMemo(() => {
    return Object.values(accept).flatMap((types) => {
      return types.map((type) => type.replace(/^\./, ""))
    })
  }, [accept])

  const handleDrop = async (accepted, rejected) => {
    setIsLoading(true)

    setErrors(
      rejected.map((file) => ({
        id: uuidv4(),
        name: file.name,
        error: I18n.t("errors.invalid-file-type-upload", {
          type: file.type,
          valid_types: validTypes
        })
      }))
    )

    try {
      const results = await Promise.all(
        accepted.map((file) => {
          return uploadAsyncFn(file).catch((error) => {
            setErrors((errors) =>
              errors.concat({
                id: uuidv4(),
                fileName: file.name,
                details: Object.values(error.response.data.errors).flat()
              })
            )
          })
        })
      )

      return onChange(results.filter(Boolean))
    } finally {
      setIsLoading(false)
    }
  }

  const files = (multiple ? value : [value]).filter(Boolean)
  const showDropzone = multiple || !value

  return (
    <Box>
      <Stack gap={3}>
        {showDropzone && (
          <Dropzone
            multiple={multiple}
            disabled={isLoading || disabled}
            accept={accept}
            onDrop={handleDrop}
            maxSize={MAX_FILE_SIZE}
          >
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <Box
                  {...getRootProps()}
                  sx={{
                    border: "1px dashed #dfdfdf",
                    borderRadius: "8px",
                    padding: "32px",
                    backgroundColor: "#fff",
                    position: "relative",
                    ...(isDragActive
                      ? { borderColor: "primary.main", borderStyle: "solid" }
                      : {}),
                    ...(error ? { borderColor: "error.main" } : {})
                  }}
                >
                  <input {...getInputProps({ name: "design" })} />
                  <Box
                    sx={{
                      display: "grid",
                      height: "100%",
                      placeItems: "center"
                    }}
                  >
                    {isLoading && (
                      <Box position="absolute">
                        <CircularProgress color="primary" />
                      </Box>
                    )}
                    <Stack
                      gap={3}
                      alignItems="center"
                      sx={{ opacity: isLoading ? 0.1 : 1 }}
                    >
                      <CloudUploadIcon
                        sx={{
                          fontSize: 66,
                          color: error ? "error.main" : "#34384d",
                          opacity: error ? 0.8 : 0.4
                        }}
                      />
                      <Stack gap={1} alignItems="center">
                        <Typography
                          variant="h5"
                          color={error ? "error" : "inherit"}
                        >
                          <Translate value="drag-n-drop" />
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight={400}
                          color={error ? "error" : "inherit"}
                        >
                          {hint}
                        </Typography>
                      </Stack>
                      <Button
                        disabled={isLoading || disabled}
                        variant="outlined"
                        color={error ? "error" : "inherit"}
                      >
                        <Translate value="select-file" />
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              )
            }}
          </Dropzone>
        )}
        {!!errors.length && (
          <Box>
            <Typography variant="h5">
              <Translate value="failed" number={errors.length} />
            </Typography>
            {errors.map((error) => (
              <DropzoneHelperText
                key={error.id}
                variant="error"
                value={error}
              />
            ))}
          </Box>
        )}
      </Stack>
      {!!files.length && (
        <List sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
          {files.map((file, index) => (
            <UploadedFile
              key={file.id ?? index}
              file={file}
              onDelete={onDelete}
              onDeleteSuccess={onDeleteSuccess}
              onPreview={onPreview}
            />
          ))}
        </List>
      )}
      <Stack gap={2}>
        {embeddedErrors}
        {error && helperText && (
          <Typography color="error">{helperText}</Typography>
        )}
      </Stack>
    </Box>
  )
}

export const FilesDropzone = React.memo(FilesDropzoneComponent)
