import React from "react"
import { Translate } from "react-redux-i18n"
import { Stack, Typography } from "@mui/material"
import kebabCase from "lodash/kebabCase"
import moment from "moment"

const formatChange = (value) => {
  if (
    !(!isNaN(parseFloat(value)) && isFinite(value)) &&
    !!Date.parse(value) &&
    !Array.isArray(value)
  ) {
    if (value.length > 10) {
      return moment(value).format("DD.MM.YYYY HH:mm:ss")
    }
    return moment(value).format("DD.MM.YYYY")
  }

  if (Array.isArray(value)) {
    return JSON.stringify(value)
  }

  return value ? (
    value.toString()
  ) : (
    <Translate className="value" value="blank" />
  )
}

const ChangesCell = ({ label, labelPrefix, changeset }) => {
  const [from, to] = changeset
  const labelValue = labelPrefix ? `${labelPrefix}.${kebabCase(label)}` : label

  return (
    <Stack key={label} direction="row" spacing={0.5}>
      <Typography
        sx={{
          fontSize: "0.75rem",
          fontWeight: 500,
          color: "grey.600"
        }}
      >
        <Translate value={labelValue} />:
      </Typography>
      <Typography sx={{ fontSize: "0.75rem" }}>
        {formatChange(from)}, {formatChange(to)}
      </Typography>
    </Stack>
  )
}

export const renderChangesCell = (info, labelPrefix) => {
  const changes = info.getValue()

  return (
    <>
      {Object.entries(changes).map(([label, changes]) => (
        <ChangesCell
          key={label}
          label={label}
          labelPrefix={labelPrefix}
          changeset={changes}
        />
      ))}
    </>
  )
}
