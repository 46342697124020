import React, { memo, useCallback, useState } from "react"
import { useDebouncedCallback } from "use-debounce"
import { IconButton, InputAdornment, TextField } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"

export const SearchInput = memo(
  /**
   * @param {Omit<import("@mui/material").TextFieldProps, 'value' | 'onChange' | 'variant'> & {initialValue: string, onChange: (string) => void; delay?: number}} props
   */
  ({ initialValue, onChange, delay = 500, ...rest }) => {
    const [value, setValue] = useState(initialValue)
    const [prevInitialValue, setPrevInitialValue] = useState(initialValue)
    const [debouncedChange] = useDebouncedCallback(onChange, delay)

    if (initialValue !== prevInitialValue) {
      setPrevInitialValue(initialValue)
      setValue(initialValue)
    }

    const handleChange = useCallback(
      (e) => {
        setValue(e.target.value)
        debouncedChange(e.target.value)
      },
      [debouncedChange]
    )

    const handleClear = useCallback(() => {
      setValue("")
      onChange("")
    }, [onChange])

    return (
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: value ? (
            <InputAdornment position="end">
              <IconButton size="small" onClick={handleClear}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : null
        }}
        {...rest}
        value={value}
        onChange={handleChange}
      />
    )
  }
)
