import React from "react"
import {
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
  useTheme
} from "@mui/material"

const CardButtonComponent = ({
  id,
  icon,
  title,
  hint,
  active = false,
  onClick
}) => {
  const theme = useTheme()
  return (
    <Card
      id={id}
      onClick={onClick}
      sx={{
        height: "100%",
        cursor: "pointer",
        border: "1px solid",
        borderColor: `${active ? theme.palette.primary.main : "#DFDFDF !important"} !important`,
        svg: {
          color: active ? "background.paper" : "unset"
        }
      }}
    >
      <CardMedia
        sx={{
          backgroundColor: active ? "primary.main" : "#F8F8F8",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 5
        }}
      >
        {icon}
      </CardMedia>
      <CardContent sx={{ p: 2, pb: "16px !important" }}>
        <Stack gap={0.5}>
          <Typography variant="h5">{title}</Typography>
          <Typography fontSize="0.625rem">{hint}</Typography>
        </Stack>
      </CardContent>
    </Card>
  )
}

export const CardButton = React.memo(CardButtonComponent)
