import { Alert, Snackbar } from "@mui/material"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import * as cookies from "shared/lib/cookies"
import { remove, useSnackbarNotifications } from "store/modules/common/notify"
import useHandleServerErrors from "hooks/useHandleServerErrors"

const autoHideDuration = 6000

export const Notifications = () => {
  const notifications = useSnackbarNotifications()
  const dispatch = useDispatch()
  const handleServerErrors = useHandleServerErrors()

  useEffect(() => {
    cookies.pop("errors", (errors) => {
      if (errors) {
        handleServerErrors({
          response: { data: { errors: JSON.parse(errors) } }
        })
      }
    })
  }, [handleServerErrors])

  const handleClose = (notificationId) => {
    dispatch(remove(notificationId))
  }

  return (
    <>
      {notifications.map((notification) => (
        <Snackbar
          key={`notify-message notify-message-${notification.id}`}
          open={true}
          autoHideDuration={
            notification.type === "error" ? null : autoHideDuration
          }
          onClose={() => {
            if (notification.type !== "error") {
              handleClose(notification.id)
            }
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            severity={notification.type}
            onClose={() => handleClose(notification.id)}
            sx={{ whiteSpace: "pre-line" }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  )
}
