import React from "react"
import { Button } from "@mui/material"
import FilterAltIcon from "@mui/icons-material/FilterAlt"

/**
 * @param {import("@mui/material").ButtonProps} props
 */
export const ClearAllButton = (props) => {
  return <Button color="primary" variant="outlined" size="small" {...props} />
}

/**
 * @param {import("@mui/material").ButtonProps} props
 */
export const FilterButton = (props) => {
  return (
    <Button
      startIcon={<FilterAltIcon />}
      color="inherit"
      size="small"
      {...props}
    />
  )
}
