import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography
} from "@mui/material"
import React from "react"
import { useDispatch } from "react-redux"
import {
  remove,
  useConfirmationNotification
} from "store/modules/common/notify"
import { Dialog } from "shared/ui/berry-jass"
import { Translate } from "react-redux-i18n"

export const ConfirmationDialog = () => {
  const notification = useConfirmationNotification()
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(remove(notification.id))
  }

  const handleCancel = () => {
    if (notification.onCancel) {
      notification.onCancel()
    }

    handleClose()
  }

  const handleConfirm = () => {
    if (notification.onConfirm) {
      notification.onConfirm()
    }
    handleClose()
  }

  if (!notification) {
    return null
  }

  return (
    <Dialog
      open
      onClose={handleCancel}
      title={notification.title}
      maxWidth="lg"
      data-testid="confirmation-dialog"
    >
      <DialogContent sx={{ mt: 3 }}>
        <Box minWidth={600}>
          <Typography gutterBottom variant="body1">
            {notification.message}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ gap: 1 }}>
        {(notification.showCancel ?? true) && (
          <Button
            variant="outlined"
            size={notification.buttonSize ?? "medium"}
            onClick={handleCancel}
          >
            {notification.cancelBtnText ?? <Translate value="actions.cancel" />}
          </Button>
        )}
        <Button
          variant="contained"
          size={notification.buttonSize ?? "medium"}
          onClick={handleConfirm}
        >
          {notification.confirmBtnText ?? <Translate value="actions.ok" />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
