import React, { useState } from "react"
import { useDropzone } from "react-dropzone"
import {
  Box,
  Chip,
  CircularProgress,
  useTheme,
  Button,
  Grid,
  Stack
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import AttachmentIcon from "@mui/icons-material/Attachment"
import Avatar from "shared/ui/berry-jass/Avatar"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import { Typography } from "@mui/material"
import { I18n } from "react-redux-i18n"

const DefaultPreview = ({ value, onDelete }) => {
  if (!value) {
    return null
  }

  return (
    <Chip
      avatar={<AttachmentIcon />}
      label={value.name ?? value.id}
      onDelete={() => onDelete(value)}
    />
  )
}

/**
 *
 * @param {{ onChange?: Function, onUpload: (File) => Promise, onDelete?: Function, showEmpty?: boolean, preview: React.FC<{value: any, onDelete: Function}>} & import("react-dropzone").DropzoneProps} props
 * @returns
 */
export const PreviewableFilePicker = ({
  onUpload,
  onDelete,
  showEmpty = false,
  preview: Preview = DefaultPreview,
  multiple,
  name,
  value,
  variant,
  label,
  children,
  onUploadStart,
  onUploadSettled,
  onUploadError,
  onChange,
  helperText,
  avatarSx,
  sx,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const disabled = isLoading || rest.disabled
  const { getRootProps, getInputProps, open } = useDropzone({
    ...rest,
    disabled,
    multiple,
    onDrop: (acceptedFiles, _rejectedFiles, e) => {
      if (!acceptedFiles.length) {
        return Promise.resolve()
      }

      setIsLoading(true)
      if (onUploadStart) {
        onUploadStart()
      }

      if (multiple) {
        return Promise.all(
          acceptedFiles.map((file) => {
            return onUpload(file)
          })
        )
          .then((data) => {
            return onChange(e, [...value, ...data])
          })
          .catch(onUploadError)
          .finally(() => {
            if (onUploadSettled) {
              onUploadSettled()
            }
            setIsLoading(false)
          })
      }

      const file = acceptedFiles[0]
      return onUpload(file)
        .then((data) => onChange(e, data))
        .catch(onUploadError)
        .finally(() => {
          if (onUploadSettled) {
            onUploadSettled()
          }
          return setIsLoading(false)
        })
    }
  })

  const handleDelete = (file) => {
    if (multiple) {
      onChange(
        null,
        value.filter((val) => val !== file)
      )
    } else {
      onChange(null, null)
    }

    if (onDelete) {
      onDelete(file)
    }
  }

  const theme = useTheme()

  return (
    <>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={2}
        justifyContent="center"
        alignItems="center"
        sx={sx}
      >
        <Grid item>
          {(showEmpty || !value) && (
            <Box {...getRootProps()}>
              {isLoading ? (
                <CircularProgress size={14} />
              ) : (
                <Avatar
                  size="xl"
                  variant={variant}
                  sx={{
                    borderStyle: "solid",
                    borderColor: theme.palette.grey[200],
                    backgroundColor: theme.palette.background.paper,
                    ...avatarSx
                  }}
                >
                  <AddIcon
                    sx={{
                      color: theme.palette.grey[200]
                    }}
                  />
                </Avatar>
              )}
            </Box>
          )}

          {multiple && (
            <Stack rowGap={2}>
              {value.map((file, i) => {
                return <Preview key={i} value={file} onDelete={handleDelete} />
              })}
            </Stack>
          )}

          {!multiple && value && (
            <Preview value={value} onDelete={handleDelete} />
          )}
          <input {...getInputProps({ name })} />
        </Grid>
        <Grid item>
          <Grid container rowSpacing={0.5}>
            <Grid item md={12}>
              <Typography variant="caption">{label}</Typography>
            </Grid>
            <Grid item md={12}>
              <Button variant="contained" onClick={open}>
                {children ?? I18n.t("upload-image")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {helperText && (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <FormControl error variant="standard">
              <FormHelperText id="component-error-text">
                {helperText}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </>
  )
}
