import React from "react"
import { Paper, Grid, Button, Typography, Stack } from "@mui/material"
import { Translate } from "react-redux-i18n"
import { useErrorBoundary } from "react-error-boundary"

export const ErrorFallback = ({ height = "inherit" }) => {
  const { resetBoundary } = useErrorBoundary()

  return (
    <Paper sx={{ display: "flex", height }}>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Grid item>
          <Stack mb={2} spacing={0.5}>
            <Typography color="red" fontWeight={900} fontSize="1rem">
              <Translate value="errors.boundary-fallback.render-error" />
            </Typography>
            <Typography color="red" fontWeight={500} fontSize="0.75rem">
              <Translate value="errors.boundary-fallback.reload-component" />
            </Typography>
          </Stack>
          <Button
            variant="contained"
            color="primary"
            type="reset"
            onClick={resetBoundary}
            sx={{ mb: 1 }}
          >
            <Translate value="reload" />
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}
