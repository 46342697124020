import useNotifications from "hooks/useNotifications"
import { useCallback } from "react"
import { toDotNotation } from "shared"
import { serverError } from "utils/parsers/serverError"

export const useHandleServerErrors = () => {
  const { showError } = useNotifications()

  return useCallback(
    (error, formik) => {
      const { data } = error.response
      const dataErrors = data.errors
      const dataError = data.error

      if (formik) {
        formik.setSubmitting(false)

        if (dataErrors) {
          if (Array.isArray(dataErrors)) {
            // Legacy errors format
            showError(dataErrors.join("\n"))
          } else {
            formik.setErrors(toDotNotation(dataErrors))
          }
        }

        if (dataErrors?.base) {
          showError(dataErrors.base.join(", "))
        }
      }

      if (!formik && dataErrors) {
        showError(serverError(dataErrors))
      }

      if (dataError) {
        showError(dataError)
      }
    },
    [showError]
  )
}

export default useHandleServerErrors
