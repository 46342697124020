import React, { useEffect } from "react"
import { Grid, Button, Typography, Stack } from "@mui/material"
import { Translate } from "react-redux-i18n"
import { useErrorBoundary } from "react-error-boundary"
import { useHistory } from "react-router-dom"

export const AppErrorFallback = ({ error, resetErrorBoundary }) => {
  const history = useHistory()
  const { resetBoundary } = useErrorBoundary()

  useEffect(() => {
    return history.listen(() => {
      if (error) {
        resetBoundary()
      }
    })
  }, [history, resetBoundary, error])

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      style={{ minHeight: "80vh" }}
    >
      <Grid item>
        <Stack mb={2} spacing={0.5}>
          <Typography color="red" fontWeight={900} fontSize="1.5rem">
            <Translate value="errors.boundary-fallback.went-wrong" />
          </Typography>
          <Typography color="red" fontWeight={500} fontSize="1rem">
            <Translate value="errors.boundary-fallback.reload-page" />
          </Typography>
        </Stack>
        <Button
          variant="contained"
          color="primary"
          type="reset"
          onClick={resetErrorBoundary}
        >
          <Translate value="reload" />
        </Button>
      </Grid>
    </Grid>
  )
}
