import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import isEmpty from "lodash/isEmpty"

import { DataTable as JBDataTable } from "shared/ui/berry-jass"
import { EmptyState } from "shared/ui/berry-jass-extended"
import { ErrorFallback } from "widgets/error-fallback"

const defaultGetRowId = (record) => record?.id

/**
 * @param {import("../berry-jass/types").DataTableProps & {
 *   loadingState?: JSX.Element,
 *   emptyState?: JSX.Element,
 *   emptyStateProps?: {text?: React.ReactNode; subText?: React.ReactNode, sx?: import("@mui/material").SxProps},
 *   isLoading: boolean,
 *   isFetching: boolean,
 * }} props
 */
export const DataTable = ({
  loadingState = null,
  emptyState = null,
  emptyStateProps = {},
  isLoading,
  isFetching,
  data,
  onStateChange,
  getRowId = defaultGetRowId,
  ...rest
}) => {
  if (isLoading && loadingState) {
    return loadingState
  }

  if (!data.length && emptyState) {
    return emptyState
  }

  if (!isFetching && isEmpty(data)) {
    return <EmptyState {...emptyStateProps} />
  }

  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <JBDataTable
        data={data}
        data-testid="berry-table"
        onStateChange={onStateChange}
        getRowId={getRowId}
        {...rest}
      />
    </ErrorBoundary>
  )
}
