import PropTypes from "prop-types"

import React from "react"

import { useTheme } from "@mui/material/styles"
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Divider,
  Typography
} from "@mui/material"

/**
 * @param {{children: React.ReactNode, title?: React.ReactNode, content?: boolean, contentClass?: string, darkTitle?: React.ReactNode, secondary?: React.ReactNode, sx?: import("@mui/material").SxProps, contentSX?: import("@mui/material").SxProps, headSx?: import("@mui/material").SxProps}} props
 */
export const SubCard = ({
  children,
  content,
  contentClass,
  darkTitle,
  secondary,
  sx = {},
  contentSX = {},
  headSx = {},
  withDivider = true,
  title,
  actions,
  ...others
}) => {
  const theme = useTheme()

  return (
    <Card
      sx={{
        border: "1px solid",
        borderColor: theme.palette.grey[400],
        ":hover": {
          boxShadow:
            theme.palette.mode === "dark"
              ? "0 2px 14px 0 rgb(33 150 243 / 10%)"
              : "0 2px 14px 0 rgb(32 40 45 / 8%)"
        },
        ...sx
      }}
      {...others}
    >
      {/* card header and action */}
      {!darkTitle && title && (
        <CardHeader
          sx={{ p: 2.5, ...headSx }}
          title={<Typography variant="h5">{title}</Typography>}
          action={secondary}
        />
      )}
      {darkTitle && title && (
        <CardHeader
          sx={{ p: 2.5, ...headSx }}
          title={<Typography variant="h4">{title}</Typography>}
          action={secondary}
        />
      )}

      {/* content & header divider */}
      {title && withDivider && (
        <Divider
          sx={{
            opacity: 1,
            borderColor: theme.palette.grey[400]
          }}
        />
      )}

      {/* card content */}
      {content && (
        <CardContent sx={{ p: 2, ...contentSX }} className={contentClass || ""}>
          {children}
        </CardContent>
      )}
      {!content && children}

      {/* card actions */}
      {actions && <CardActions>{actions}</CardActions>}
    </Card>
  )
}

SubCard.propTypes = {
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object
  ]),
  sx: PropTypes.object,
  contentSX: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object
  ])
}

SubCard.defaultProps = {
  content: true
}
