import React, { useState, useEffect } from "react"
import { Translate } from "react-redux-i18n"
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography
} from "@mui/material"
import { useDropzone } from "react-dropzone"
import { useDebouncedCallback } from "use-debounce"

export const CustomDivider = ({
  textSx = {},
  text = <Translate value="or" />
}) => {
  return (
    <Stack position="relative" justifyContent="center">
      <Divider variant="fullWidth" />
      <Typography
        variant="h5"
        sx={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          color: "#000000",
          backgroundColor: "#ffffff",
          px: 3,
          textTransform: "uppercase",
          ...textSx
        }}
        fontWeight={700}
      >
        {text}
      </Typography>
    </Stack>
  )
}

export const URLFilePicker = ({
  name,
  value,
  onChange,
  placeholder,
  getURL,
  onUploadStart,
  onUploadSettled,
  onUploadError,
  onUpload,
  onURLUpload,
  accept,
  onDelete,
  disabled,
  error,
  helperText,
  buttonText
}) => {
  const [url, setURL] = useState(getURL(value) ?? "")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!getURL(value) && !loading) {
      setURL("")
    }
  }, [loading, setURL, value, getURL])

  const handleUpload = (e, previousValue) => {
    if (url && getURL(previousValue || value) !== url) {
      setLoading(true)
      onUploadStart()
      onURLUpload(url)
        .then(handleChange)
        .catch(onUploadError)
        .finally(() => {
          setLoading(false)
          if (onUploadSettled) {
            onUploadSettled()
          }
        })
    }

    if (!url) {
      onUploadStart()
      if (onDelete && value) {
        setLoading(true)
        onDelete(value)
          .then(() => handleChange(null))
          .catch(onUploadError)
          .finally(() => {
            setLoading(false)
            onUploadSettled()
          })
      } else {
        handleChange(null)
        onUploadSettled()
      }
    }
  }
  const [debouncedHandleUpload] = useDebouncedCallback(handleUpload, 200)

  const handleInputChange = (e) => {
    debouncedHandleUpload(e, value)
    setURL(e.target.value)
  }

  const handleChange = (value) => {
    onChange(null, value)
    setURL(getURL(value) ?? "")
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    accept,
    disabled,
    multiple: false,
    onDrop: (acceptedFiles, _rejectedFiles) => {
      if (!acceptedFiles.length) {
        return Promise.resolve()
      }

      onUploadStart()

      const file = acceptedFiles[0]

      return onUpload(file)
        .then((file) => handleChange(file))
        .catch(onUploadError)
        .finally(onUploadSettled)
    }
  })

  return (
    <Stack divider={<CustomDivider />} spacing={2}>
      <TextField
        disabled={loading || disabled}
        placeholder={placeholder}
        value={url}
        onChange={handleInputChange}
        onBlur={debouncedHandleUpload}
      />
      <Stack {...getRootProps()}>
        <input {...getInputProps({ name })} />
        <Button
          disabled={loading || disabled}
          sx={{ textTransform: "none" }}
          variant="contained"
          onClick={open}
        >
          {buttonText}
        </Button>
        {!!error && (
          <FormControl error variant="standard">
            <FormHelperText id="component-error-text">
              {helperText}
            </FormHelperText>
          </FormControl>
        )}
      </Stack>
    </Stack>
  )
}
