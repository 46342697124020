import React from "react"

export const EmptyTableIcon = () => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="9" x2="48" y2="9" stroke="#434243" strokeWidth="2" />
      <rect x="4" y="4" width="2" height="2" fill="#434243" />
      <rect x="7" y="4" width="2" height="2" fill="#434243" />
      <rect x="10" y="4" width="2" height="2" fill="#434243" />
      <path
        d="M26.9167 29.6667H26.2583L26.025 29.4417C26.8417 28.4917 27.3333 27.2583 27.3333 25.9167C27.3333 22.925 24.9083 20.5 21.9167 20.5C18.925 20.5 16.5 22.925 16.5 25.9167C16.5 28.9083 18.925 31.3333 21.9167 31.3333C23.2583 31.3333 24.4917 30.8417 25.4417 30.025L25.6667 30.2583V30.9167L29.8333 35.075L31.075 33.8333L26.9167 29.6667ZM21.9167 29.6667C19.8417 29.6667 18.1667 27.9917 18.1667 25.9167C18.1667 23.8417 19.8417 22.1667 21.9167 22.1667C23.9917 22.1667 25.6667 23.8417 25.6667 25.9167C25.6667 27.9917 23.9917 29.6667 21.9167 29.6667Z"
        fill="#434243"
      />
      <rect
        x="1"
        y="1"
        width="46"
        height="47"
        rx="3"
        stroke="#434243"
        strokeWidth="2"
      />
    </svg>
  )
}
