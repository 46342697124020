import React, { useEffect, useRef, useState } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel
} from "@mui/material"
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable
} from "@tanstack/react-table"
import { visuallyHidden } from "@mui/utils"
import { useInView } from "react-intersection-observer"
import { useVirtualizer } from "@tanstack/react-virtual"
import { useDrag, useDrop } from "react-dnd"
import { COLUMN_WIDTH_AUTO, reorderColumns } from "shared/lib"

/**
 * @param {import("@tanstack/react-table").Header} cell
 */
const getHeaderZIndex = (cell) => {
  const ctx = cell.getContext()
  if (cell.column.getIsPinned()) {
    return (
      ctx.table.getVisibleFlatColumns().length +
      ctx.table.getLeftLeafColumns().length +
      ctx.table.getRightLeafColumns().length -
      cell.column.getPinnedIndex() +
      1
    )
  }

  return ctx.table.getVisibleFlatColumns().length - cell.index
}

/**
 * @param {import("@tanstack/react-table").Cell} cell
 * @returns {number}
 */
const getCellZIndex = (cell) => {
  if (cell.column.getIsPinned()) {
    const ctx = cell.getContext()

    return (
      ctx.table.getVisibleFlatColumns().length +
      ctx.table.getLeftLeafColumns().length +
      ctx.table.getRightLeafColumns().length -
      cell.column.getPinnedIndex()
    )
  }

  return 0
}

const getPinnedSx = (column, positions) => {
  const isPinned = column.getIsPinned()
  if (!isPinned) {
    return null
  }

  const side = isPinned.toString()
  const pinnedIndex = column.getPinnedIndex()

  return {
    position: "sticky",
    backgroundColor: "white",
    [side]: positions[side][pinnedIndex],
    ...(positions.isBordering(column.id)
      ? {
          boxShadow: `inset ${
            isPinned === "left" ? "-" : ""
          }3px 0px 0 0px #eeeeee`
        }
      : {})
  }
}

/**
 * @param {import("@tanstack/react-table").Table} table
 * @returns
 */
const getStickyPositions = (table) => {
  const leftColumns = table.getLeftLeafColumns()
  const left = [0]

  for (let i = 1; i < leftColumns.length; i++) {
    left.push(leftColumns[i - 1].getSize() + left[i - 1])
  }

  const rightColumns = table.getRightLeafColumns()
  const right = [0]
  for (let i = 1; i < rightColumns.length; i++) {
    right.unshift(
      rightColumns[rightColumns.length - i].getSize() + right[i - 1]
    )
  }
  const delimiters = {
    left: leftColumns?.[leftColumns?.length - 1]?.id,
    right: rightColumns?.[0]?.id
  }

  return {
    left,
    right,
    isBordering(id) {
      return delimiters.left === id || delimiters.right === id
    }
  }
}

/**
 * @param {object} props
 * @param {import("@tanstack/react-table").Table} props.table
 * @param {import("@tanstack/react-table").Header} props.header
 * @param {object} props.stickyPositions
 * @param {boolean} props.enableOrdering
 */
const DraggableColumnHeader = ({
  header,
  table,
  stickyPositions,
  enableOrdering
}) => {
  const { getState, setColumnOrder, setColumnPinning } = table
  const { columnOrder, columnPinning } = getState()
  const { column } = header
  const cellSx = header.column.columnDef.meta?.cellSx

  const [, dropRef] = useDrop({
    accept: "column",
    /** @param {import("@tanstack/react-table").Column} draggedColumn */
    drop: (draggedColumn) => {
      const newColumnOrder = reorderColumns(
        draggedColumn.id,
        column.id,
        columnOrder
      )
      setColumnOrder(newColumnOrder)

      if (
        draggedColumn.getIsPinned() === column.getIsPinned() &&
        !!column.getIsPinned()
      ) {
        const newColumnPinning = reorderColumns(
          draggedColumn.id,
          column.id,
          columnPinning[column.getIsPinned().toString()]
        )

        setColumnPinning({
          ...columnPinning,
          [column.getIsPinned().toString()]: newColumnPinning
        })
      }
    }
  })

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    item: () => column,
    type: "column"
  })

  return (
    <TableCell
      ref={enableOrdering ? dropRef : null}
      sx={{
        opacity: isDragging ? 0.5 : 1,
        zIndex: getHeaderZIndex(header),
        ...cellSx,
        ...getPinnedSx(header.column, stickyPositions),
        "&:hover .resizer": {
          display: "block"
        }
      }}
    >
      <div ref={enableOrdering ? previewRef : null}>
        <div ref={enableOrdering ? dragRef : null}>
          {header.column.columnDef.enableSorting ? (
            <TableSortLabel
              active={!!header.column.getIsSorted()}
              direction={header.column.getIsSorted() || "asc"}
              onClick={header.column.getToggleSortingHandler()}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}

              {header.column.getIsSorted() ? (
                <Box component="span" sx={visuallyHidden}>
                  {header.column.getIsSorted() === "desc"
                    ? "sorted descending"
                    : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          ) : (
            flexRender(header.column.columnDef.header, header.getContext())
          )}
        </div>

        {header.column.columnDef.enableResizing && (
          <Box
            className="resizer"
            sx={{
              marginLeft: "4px",
              position: "absolute",
              right: 0,
              top: 0,
              height: "100%",
              width: "4px",
              background: "#5180DC",
              cursor: "col-resize",
              userSelect: "none",
              touchAction: "none",
              display: "none",
              zIndex: 20
            }}
            style={
              header.column.getIsResizing()
                ? {
                    transform: `translateX(${
                      table.getState().columnSizingInfo.deltaOffset
                    }px)`,
                    background: "blue",
                    opacity: 1,
                    height: "10000vh",
                    width: "1px",
                    display: "block"
                  }
                : null
            }
            onMouseDown={header.getResizeHandler()}
            onTouchStart={header.getResizeHandler()}
          />
        )}
      </div>
    </TableCell>
  )
}

const defaultInitialState = {
  sorting: [],
  columnVisibility: {},
  columnPinning: { left: [], right: [] },
  columnOrder: null,
  columnSizing: {}
}

/**
 * @param {import("./types").DataTableProps} props
 */
export const DataTable = ({
  sx,
  footerSx,
  data,
  meta,
  columns,
  onSort,
  canFetchMore,
  initialState = defaultInitialState,
  stickyHeader = false,
  hideHeader = false,
  withFooter = false,
  getRowId = undefined,
  getRowCanExpand,
  fetchMore,
  rootMargin = "500px",
  onStateChange,
  renderSubComponent,
  renderTopRow = null,
  ...tableProps
}) => {
  const [sorting, setSorting] = useState(() => initialState.sorting ?? [])

  useEffect(() => {
    if (onSort) {
      onSort(sorting)
    }
  }, [onSort, sorting])

  const table = useReactTable({
    data: data,
    meta: meta,
    columns: columns,
    state: {
      sorting,
      columnVisibility: initialState.columnVisibility ?? {},
      columnOrder: initialState.columnOrder ?? [],
      columnSizing: initialState.columnSizing ?? {},
      columnPinning: initialState.columnPinning ?? { left: [], right: [] }
    },
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowId,
    manualSorting: true,
    sortDescFirst: true,
    columnResizeMode: "onEnd",
    onSortingChange: setSorting,
    onColumnOrderChange: (columnOrder) => {
      onStateChange({ ...table.getState(), columnOrder })
    },
    onColumnPinningChange: (columnPinning) => {
      onStateChange({ ...table.getState(), columnPinning })
    },
    onColumnSizingChange: (updater) => {
      return onStateChange({
        ...table.getState(),
        columnSizing:
          typeof updater === "function"
            ? updater(table.getState().columnSizing)
            : updater
      })
    },
    enableSortingRemoval: true
  })

  const stickyPositions = getStickyPositions(table)

  const rootRef = useRef(null)
  const { ref: lastRowRef } = useInView({
    root: rootRef.current,
    rootMargin,
    onChange: (inView) => {
      if (inView && fetchMore && canFetchMore) {
        fetchMore()
      }
    }
  })

  const { rows } = table.getRowModel()

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => rootRef.current,
    count: rows.length,
    overscan: 35,
    estimateSize: () => 50
  })
  const virtualRows = rowVirtualizer.getVirtualItems()
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
  const paddingBottom =
    virtualRows.length > 0
      ? rowVirtualizer.getTotalSize() -
        (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0

  return (
    <DndProvider backend={HTML5Backend}>
      <Box
        sx={{
          height: "100%",
          maxHeight: "100%",
          position: "relative",
          overflow: "hidden"
        }}
      >
        <TableContainer sx={{ maxHeight: "100%", ...sx }} ref={rootRef}>
          <Table
            stickyHeader={stickyHeader}
            sx={{ tableLayout: "fixed", maxHeight: "100%" }}
            style={{
              width: onStateChange ? table.getCenterTotalSize() : "100%"
            }}
            {...tableProps}
          >
            {!hideHeader && (
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => {
                  return (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <DraggableColumnHeader
                            key={header.id}
                            table={table}
                            stickyPositions={stickyPositions}
                            header={header}
                            enableOrdering={!!onStateChange}
                          />
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableHead>
            )}
            {paddingTop > 0 && (
              <TableBody>
                <TableRow>
                  <TableCell style={{ height: `${paddingTop}px` }} />
                </TableRow>
              </TableBody>
            )}
            <colgroup>
              {table.getHeaderGroups().map((headerGroup) => {
                return headerGroup.headers.map((header) => {
                  return (
                    <col
                      key={header.id}
                      width={
                        header.column.columnDef.size === COLUMN_WIDTH_AUTO
                          ? undefined
                          : header.column.getSize()
                      }
                    />
                  )
                })
              })}
            </colgroup>

            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index]
              const highlightError = row
                .getAllCells()
                .some((cell) =>
                  cell.column.columnDef.meta?.getRowError?.(cell.row.index)
                )

              return (
                <TableBody
                  key={virtualRow.key}
                  data-index={virtualRow.index}
                  ref={rowVirtualizer.measureElement}
                >
                  {renderTopRow && renderTopRow(row)}
                  <TableRow
                    data-row-id={row.id}
                    hover
                    sx={{
                      backgroundColor: highlightError ? "#FF00001A" : "initial"
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      const cellSx = cell.column.columnDef.meta?.cellSx

                      return (
                        <TableCell
                          key={cell.id}
                          sx={{
                            overflow: "hidden",
                            zIndex: getCellZIndex(cell),
                            ...getPinnedSx(cell.column, stickyPositions),
                            ...cellSx
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  {renderSubComponent && row.getIsExpanded() && (
                    <TableRow>
                      <TableCell
                        colSpan={row.getVisibleCells().length}
                        sx={{ padding: 0 }}
                      >
                        {renderSubComponent({ row })}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )
            })}
            <TableBody>
              <TableRow ref={lastRowRef} />
              {paddingBottom > 0 && (
                <TableRow>
                  <TableCell style={{ height: `${paddingBottom}px` }} />
                </TableRow>
              )}
            </TableBody>
            {withFooter && (
              <TableFooter
                sx={{ position: "sticky", zIndex: 200, ...footerSx }}
              >
                {table.getFooterGroups().map((footerGroup) => {
                  return (
                    <TableRow key={footerGroup.id}>
                      {footerGroup.headers.map((header) => {
                        if (!header.column.columnDef.footer) {
                          return null
                        }

                        const footerSx = header.column.columnDef.meta?.footerSx

                        return (
                          <TableCell
                            key={header.id}
                            colSpan={
                              header.column.columnDef.meta?.footerColSpan
                            }
                            sx={{
                              overflow: "hidden",
                              border: "none",
                              zIndex: getHeaderZIndex(header),
                              ...getPinnedSx(header.column, stickyPositions),
                              ...(footerSx ?? {})
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.footer,
                              header.getContext()
                            )}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Box>
    </DndProvider>
  )
}
