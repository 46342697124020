import { is as userIs, hasAnyOfRoles } from "utils/user"
import get from "lodash/get"
import includes from "lodash/includes"
import createSelector from "re-reselect"
import { prepareUser } from "./helper"
import { createReducer } from "store/helpers/reducers"
import deepMerge from "deepmerge"
import * as apiUtils from "utils/api"
import * as WS from "shared/lib/web-sockets"
import { addUserIdTag } from "components/App/appsignal"

const prefix = "jass/auth"
const LOAD_USER_START = `${prefix}/LOAD_USER_START`
const LOAD_USER_SUCCESS = `${prefix}/LOAD_USER_SUCCESS`
const LOAD_USER_FAIL = `${prefix}/LOAD_USER_FAIL`
const LOGIN_START = `${prefix}/LOGIN_START`
const LOGIN_SUCCESS = `${prefix}/LOGIN_SUCCESS`
const LOGIN_FAIL = `${prefix}/LOGIN_FAIL`
const LOGOUT_START = `${prefix}/LOGOUT_START`
const LOGOUT_SUCCESS = `${prefix}/LOGOUT_SUCCESS`
const LOGOUT_FAIL = `${prefix}/LOGOUT_FAIL`
const CHANGE_PASSWORD_START = `${prefix}/CHANGE_PASSWORD_START`
const CHANGE_PASSWORD_SUCCESS = `${prefix}/CHANGE_PASSWORD_SUCCESS`
const CHANGE_PASSWORD_FAIL = `${prefix}/CHANGE_PASSWORD_FAIL`
const UPDATE_USER_SETTINGS_START = `${prefix}/UPDATE_USER_SETTINGS_START`
const UPDATE_USER_SETTINGS_SUCCESS = `${prefix}/UPDATE_USER_SETTINGS_SUCCESS`
const UPDATE_USER_SETTINGS_FAIL = `${prefix}/UPDATE_USER_SETTINGS_FAIL`

const UPDATE_USER_INFO = `${prefix}/UPDATE_USER_INFO`

const INCREASE_NOTIFICATION_BADGE = "jass/auth/INCREASE_NOTIFICATION_BADGE"
const DECREASE_NOTIFICATION_BADGE = "jass/auth/DECREASE_NOTIFICATION_BADGE"
const CLEAR_NOTIFICATION_BADGE = "jass/auth/CLEAR_NOTIFICATION_BADGE"

const maintenancePrefix = "jass/auth/maintenance"
const CHANGE_PASSWORD_MAINTENANCE_START = `${maintenancePrefix}/CHANGE_PASSWORD_START`
const CHANGE_PASSWORD_MAINTENANCE_SUCCESS = `${maintenancePrefix}/CHANGE_PASSWORD_SUCCESS`
const CHANGE_PASSWORD_MAINTENANCE_FAIL = `${maintenancePrefix}/CHANGE_PASSWORD_FAIL`

const handleUserLoad = (_state, action) => {
  const user = prepareUser((action.result?.body ?? action.result)?.user ?? {})
  return { loaded: true, token: action.result?.auth_token ?? "", user }
}

export default createReducer(
  {},
  {
    [LOAD_USER_SUCCESS]: handleUserLoad,
    [UPDATE_USER_INFO]: (state, action) => {
      return { ...state, user: prepareUser(action.payload.user) }
    },
    [LOGIN_SUCCESS]: handleUserLoad,
    [LOAD_USER_FAIL]: () => ({ loaded: true }),
    [UPDATE_USER_SETTINGS_SUCCESS]: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        settings: action.result.user_settings
      }
    }),
    [INCREASE_NOTIFICATION_BADGE]: (state) => ({
      ...state,
      user: {
        ...state.user,
        unread_notifications_count: state.user.unread_notifications_count + 1
      }
    }),
    [DECREASE_NOTIFICATION_BADGE]: (state) => {
      const unreadCount = state.user.unread_notifications_count
      const newUnreadCount = unreadCount > 0 ? unreadCount - 1 : 0
      return {
        ...state,
        user: {
          ...state.user,
          unread_notifications_count: newUnreadCount
        }
      }
    },
    [CLEAR_NOTIFICATION_BADGE]: (state) => ({
      ...state,
      user: {
        ...state.user,
        unread_notifications_count: 0
      }
    })
  }
)

export const load = () => {
  return {
    types: [LOAD_USER_START, LOAD_USER_SUCCESS, LOAD_USER_FAIL],
    promise: (client) => client.get("session_tokens"),
    postProcess: (res) => {
      configureRollbar({ userId: res.user?.id })
      return res
    }
  }
}

export const updateUserInfo = (user) => {
  return {
    type: UPDATE_USER_INFO,
    payload: { user }
  }
}

export const loadUser = () => {
  return (dispatch, getState) => {
    const user = getUser(getState())
    if (!user) {
      return dispatch(load())
    }
    return Promise.resolve({ user })
  }
}

export const login = (values) => {
  return {
    types: [LOGIN_START, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: (client) =>
      client.post("session_tokens", { ...values, includeHeaders: true }),
    postProcess: (response) => {
      const user = response.body.user
      configureRollbar({ userId: user?.id })
      if (user?.id) WS.setup(user)
      return response
    }
  }
}

export const loginSuccess = (response) => {
  return {
    type: LOGIN_SUCCESS,
    result: response
  }
}

export const logout = () => {
  WS.teardown()

  return {
    types: [LOGOUT_START, LOGOUT_SUCCESS, LOGOUT_FAIL],
    promise: (client) => client.del("users/sign_out.json"),
    postProcess: (res) => {
      configureRollbar({ userId: null })
      apiUtils.deleteAuthCookie()
      return res
    }
  }
}

const genericChangePassword = (url, values) => {
  return {
    types: [
      CHANGE_PASSWORD_START,
      CHANGE_PASSWORD_SUCCESS,
      CHANGE_PASSWORD_FAIL
    ],
    promise: (client) => {
      return client.put(url, {
        data: { user: values },
        includeHeaders: true
      })
    }
  }
}

export const changePassword = (values) => {
  return (dispatch) => {
    let url = "password/edit"

    if (values.reset_password_token) {
      url = "users/password"
    } else if (values.confirmation_token) {
      url = "users/confirmations"
    }

    return dispatch(genericChangePassword(url, values))
  }
}

export const updateUserSettings = ({ data }) => {
  return {
    types: [
      UPDATE_USER_SETTINGS_START,
      UPDATE_USER_SETTINGS_SUCCESS,
      UPDATE_USER_SETTINGS_FAIL
    ],
    options: { ignoreConcurrency: true },
    promise: (client) =>
      client.put("user_settings.json", { data: { user_settings: data } })
  }
}

export const setUserSettings = (settings) => {
  return {
    type: UPDATE_USER_SETTINGS_SUCCESS,
    result: {
      user_settings: settings
    }
  }
}

export const updateAdEditorLayoutPanelState = (state) => {
  return (dispatch, getState) => {
    const settings = getState().auth.user.settings
    const data = deepMerge(settings, { panels: { "adEditor.layout": state } })

    return dispatch(updateUserSettings({ data }))
  }
}

export const updateAdhocAdSettings = (settings) =>
  updateUserSettings({ data: { adhoc_ad: { adEditor: settings } } })

function configureRollbar({ userId }) {
  // @ts-ignore
  if (global.Rollbar) {
    // @ts-ignore
    global.Rollbar.configure({ payload: { userId } })
  }

  addUserIdTag(userId)
}

export const increaseNotificationBadge = () => ({
  type: INCREASE_NOTIFICATION_BADGE
})

export const decreaseNotificationBadge = () => ({
  type: DECREASE_NOTIFICATION_BADGE
})

export const clearNotificationBadge = () => ({ type: CLEAR_NOTIFICATION_BADGE })

export const maintenanceChangePassword = (userId, password) => ({
  types: [
    CHANGE_PASSWORD_MAINTENANCE_START,
    CHANGE_PASSWORD_MAINTENANCE_SUCCESS,
    CHANGE_PASSWORD_MAINTENANCE_FAIL
  ],
  promise: (client) =>
    client.put("/maintenance/change_password.json", {
      data: { user_id: userId, password: password }
    })
})

export const getShowExternalQuota = (state) =>
  includes(
    ["partner", "agency", "end_customer"],
    state.auth.user?.account_owner_type
  ) || hasAnyOfRoles(state.auth.user, ["agencyManager"])

export const getUser = (state) => state.auth.user

export const getAgencyId = (state) => {
  const user = getUser(state)
  return user?.agency?.id ?? user?.agency_id
}

export const canManageAdhocProducts = (state) =>
  !!state.auth.user.agency?.manage_ad_hoc_products

export const canManageSubscriptions = (state) =>
  get(state.auth.user, "agency.manage_subscription") ||
  get(state.auth.user, "account_member_data.manage_subscription")

export const isBurike = createSelector(getUser, (user) =>
  userIs(user, "burike")
)(() => "isBurike")

export const isAgency = (state) => userIs(getUser(state), "agency")

export const isAgencyAccountOwner = (state) =>
  state.auth.user?.isAgency ||
  state.auth.user?.isAgencyAccountMember ||
  state.auth.user?.isAgencyAccountAdmin

const getSettings = (state) => state.auth?.user?.settings ?? {}

export const getAdEditorLayoutPanelState = (state) =>
  getSettings(state).panels?.["adEditor.layout"] === "true" ?? true

export const isAgencyManager = (state) =>
  hasAnyOfRoles(state.auth.user, ["agencyManager"])
