import React from "react"
import { I18n, Translate } from "react-redux-i18n"
import {
  ClearAllButton as UIClearAllButton,
  FilterButton as UIFilterButton
} from "shared/ui/berry-jass"
import CloseIcon from "@mui/icons-material/Close"

/**
 * @param {import("@mui/material").ButtonProps} props
 */
export const ClearAllButton = (props) => {
  return (
    <UIClearAllButton
      title={I18n.t("clear-all")}
      sx={{ minWidth: "10px", height: "32px" }}
      {...props}
    >
      <CloseIcon sx={{ fontSize: "1rem !important" }} />
    </UIClearAllButton>
  )
}

/**
 * @param {import("@mui/material").ButtonProps} props
 */
export const FilterButton = (props) => {
  return (
    <UIFilterButton {...props}>
      <Translate value="filter" />
    </UIFilterButton>
  )
}
