import {
  Box,
  InputAdornment,
  Popover,
  TextField,
  useControlled
} from "@mui/material"
import React, { memo, useCallback, useState } from "react"
import { ChromePicker } from "react-color"
import PaletteIcon from "@mui/icons-material/Palette"

export const ColorPicker = memo(
  /**
   * @param {import("@mui/material").TextFieldProps & { value: string, onChange: function }} props
   */
  ({ value: valueProp, defaultValue, onChange, disabled, ...rest }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [value, setValue] = useControlled({
      controlled: valueProp,
      default: defaultValue,
      name: "ColorPicker"
    })

    const handleChangeComplete = useCallback(
      (color) => {
        if (disabled) {
          return
        }

        setValue(color.hex)
        if (onChange) {
          onChange(color.hex)
        }
      },
      [disabled, onChange, setValue]
    )

    const handleInputChange = useCallback(
      (event) => {
        return handleChangeComplete({ hex: event.target.value })
      },
      [handleChangeComplete]
    )

    return (
      <Box width="100%">
        <TextField
          value={value}
          onChange={handleInputChange}
          onClick={(e) => {
            setAnchorEl(e.target)
          }}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PaletteIcon />
              </InputAdornment>
            )
          }}
          {...rest}
        />
        <Popover
          sx={{ mt: 1 }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          onClose={() => {
            setAnchorEl(null)
          }}
        >
          <ChromePicker color={value} onChangeComplete={handleChangeComplete} />
        </Popover>
      </Box>
    )
  }
)
