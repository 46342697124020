import moment from "moment"
import { I18n } from "react-redux-i18n"

/**
 * @param {number} price
 * @param {Intl.NumberFormatOptions} [options]
 * @returns {string} formatted price
 */
export const formatPrice = (price, options) => {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: options?.minimumFractionDigits ?? 0,
    ...options
  }).format(price)
}

/**
 * @param {number} price
 * @param {Intl.NumberFormatOptions} [options]
 * @returns {string} formatted price
 */
export const formatPriceWithCents = (price, options) => {
  return new Intl.NumberFormat(
    "de-DE",
    options ?? {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  ).format(price)
}

/**
 * @param {number} value
 * @param {Intl.NumberFormatOptions} [options]
 * @returns {string} formatted number
 */
export const formatNumber = (value, options) => {
  return new Intl.NumberFormat(
    "de-DE",
    options ?? {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  ).format(value)
}

const defaultFormat = "DD.MM.YYYY HH:mm"
export const formatDateTime = (date, format = defaultFormat) =>
  moment(date).format(format)

export const localizedDateTime = (date) =>
  date && I18n.l(date, { dateFormat: "time.formats.date-time" })

export const formatDuration = (duration) => {
  if (duration !== null && duration !== undefined) {
    const seconds = Math.floor(duration % 60)
    const minutes = Math.floor((duration % 3600) / 60)
    const hours = Math.floor(duration / 3600)

    return [
      `${hours < 10 ? "0" : ""}${hours}`,
      `${minutes < 10 ? "0" : ""}${minutes}`,
      `${seconds < 10 ? "0" : ""}${seconds}`
    ].join(":")
  }

  return null
}
