import React from "react"
import { useHistory, useParams, useRouteMatch } from "react-router"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import { getBasePath } from "shared/lib"

export const PageTabs = ({ children }) => {
  const history = useHistory()
  const match = useRouteMatch()
  /** @type {{type: string}} */
  const { type } = useParams()
  const handleChange = (event, newValue) => {
    const basePath = getBasePath(match.url)
    history.push(`${basePath}/${newValue}`)
  }

  return (
    <TabContext value={type}>
      <TabList
        onChange={handleChange}
        sx={{ ".MuiTabs-flexContainer": { border: "none" } }}
      >
        {children}
      </TabList>
    </TabContext>
  )
}
