import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { error, success, info, warning } from "store/modules/common/notify"

const useNotifications = () => {
  const dispatch = useDispatch()

  return {
    showError: useCallback(
      (message) => {
        dispatch(error(message))
      },
      [dispatch]
    ),
    showWarning: useCallback(
      (message) => {
        dispatch(warning(message))
      },
      [dispatch]
    ),
    showSuccess: useCallback(
      (text) => {
        dispatch(success(text))
      },
      [dispatch]
    ),
    showInfo: useCallback(
      (message) => {
        dispatch(info(message))
      },
      [dispatch]
    )
  }
}

export default useNotifications
