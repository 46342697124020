import React from "react"
import {
  ButtonBase,
  Paper,
  Stack,
  Box,
  CircularProgress,
  Typography
} from "@mui/material"

export const PanelButton = ({
  loading = false,
  disabled = false,
  selected = false,
  icon,
  text,
  paperProps = {},
  ...buttonProps
}) => {
  return (
    <ButtonBase
      sx={{
        height: 90,
        width: 90,
        borderRadius: 2,
        ...(disabled && {
          opacity: 0.4
        })
      }}
      {...buttonProps}
      disabled={loading || disabled}
    >
      <Paper
        square
        elevation={2}
        sx={{
          height: 90,
          width: 90,
          borderRadius: 2,
          ...(selected && {
            backgroundColor: "primary.main",
            color: "primary.contrastText"
          })
        }}
        {...paperProps}
      >
        {loading ? (
          <Stack justifyContent="center" alignItems="center" height="100%">
            <CircularProgress size={30} />
          </Stack>
        ) : (
          <Box pt={3} px={1}>
            <Box>{icon}</Box>
            <Box>
              <Typography fontSize={10}>{text}</Typography>
            </Box>
          </Box>
        )}
      </Paper>
    </ButtonBase>
  )
}
