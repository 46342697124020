import React from "react"

export const PdfIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19.3599 21.9999H4.64006C4.28655 21.9999 4 21.7133 4 21.3598V2.64006C4 2.28655 4.28655 2 4.64006 2H15.5799V5.64567C15.5799 6.07327 15.9267 6.42008 16.3543 6.42008H20V21.3599C20 21.7134 19.7135 21.9999 19.3599 21.9999L19.3599 21.9999ZM6.8836 14.96H7.69681V13.8512H8.31455C9.1435 13.8512 9.80867 13.4077 9.80867 12.5524V12.5418C9.80867 11.7867 9.27546 11.264 8.39365 11.264H6.88364L6.8836 14.96ZM7.69681 13.1278V11.9979H8.32501C8.73169 11.9979 8.985 12.1932 8.985 12.5576V12.5682C8.985 12.8849 8.74743 13.1278 8.34091 13.1278L7.69681 13.1278ZM10.4106 14.96H11.852C13.0137 14.96 13.8161 14.1522 13.8161 13.1121V13.1015C13.8161 12.0613 13.0137 11.264 11.852 11.264H10.4106V14.96ZM11.852 11.9979C12.5173 11.9979 12.9661 12.4573 12.9661 13.112V13.1226C12.9661 13.7773 12.5173 14.2261 11.852 14.2261H11.2237V11.9979L11.852 11.9979ZM14.5395 14.96H15.3527V13.5292H17.1162V12.79H15.3527V12.0033H17.3537V11.2641H14.5395V14.96ZM15.9998 5.3601V2.00021L19.9998 6.00016H16.6399C16.2864 6.00016 15.9998 5.71361 15.9998 5.3601Z"
        fill="#434243"
      />
    </svg>
  )
}
