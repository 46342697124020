/**
 * @param {string} draggedColumnId
 * @param {string} targetColumnId
 * @param {string[]} columnOrder
 * @returns {string[]}
 */
export const reorderColumns = (
  draggedColumnId,
  targetColumnId,
  columnOrder
) => {
  const order = [...columnOrder]

  order.splice(
    order.indexOf(targetColumnId),
    0,
    order.splice(columnOrder.indexOf(draggedColumnId), 1)[0]
  )

  return order
}

export const COLUMN_WIDTH_AUTO = -1
