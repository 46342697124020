import React, { useRef, useState } from "react"
import { useDropzone } from "react-dropzone"
import {
  Box,
  Chip,
  CircularProgress,
  useTheme,
  Button,
  Grid,
  DialogActions
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import AttachmentIcon from "@mui/icons-material/Attachment"
import Avatar from "shared/ui/berry-jass/Avatar"
import { I18n } from "react-redux-i18n"
import { Typography, DialogContent, Divider, Slider } from "@mui/material"
import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"
import { Dialog } from "shared/ui/berry-jass"
import { GlobalStyles } from "@mui/material"

const DefaultPreview = ({ value, onDelete }) => {
  if (!value) {
    return null
  }

  return (
    <Chip
      avatar={<AttachmentIcon />}
      label={value.name ?? value.id}
      onDelete={onDelete}
    />
  )
}

export const CropFilePicker = ({
  onUpload,
  onDelete,
  showEmpty = false,
  preview: Preview = DefaultPreview,
  name,
  value,
  label,
  children,
  onUploadStart,
  onUploadSettled,
  onChange,
  cancelText,
  confirmText,
  cropModalTitle,
  mode = "circle",
  initialAspectRatio = 1,
  cropBoxResizable = false,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [dialogOpened, setDialogOpened] = useState(false)
  const [image, setImage] = useState(null)
  const [zoomRatio, setZoomRatio] = useState(0.5)
  const theme = useTheme()

  const disabled = isLoading || rest.disabled

  const cropperRef = useRef(null)

  const { getRootProps, getInputProps, open } = useDropzone({
    ...rest,
    disabled,
    onDrop: async (acceptedFiles, _rejectedFiles) => {
      if (!acceptedFiles.length) {
        return
      }
      const file = acceptedFiles[0]
      const reader = new FileReader()
      reader.onload = () => {
        setImage(reader.result)
      }
      reader.readAsDataURL(file)
      setDialogOpened(true)
    }
  })

  const uploadCroppedImage = (file) => {
    setIsLoading(true)
    if (onUploadStart) {
      onUploadStart()
    }
    return onUpload(file)
      .then((data) => onChange(null, data))
      .finally(() => {
        if (onUploadSettled) {
          onUploadSettled()
        }
        return setIsLoading(false)
      })
  }

  const handleDelete = (file) => {
    onChange(null, null)

    if (onDelete) {
      onDelete(file)
    }
  }

  const handleSave = () => {
    cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
      const file = new File([blob], "profile_picture.jpg")
      uploadCroppedImage(file).then(() => closeDialog())
    })
  }

  const closeDialog = () => {
    setDialogOpened(false)
    setZoomRatio(0.5)
  }

  return (
    <>
      {dialogOpened && mode === "circle" && (
        <GlobalStyles
          styles={{
            ".cropper-view-box": {
              borderRadius: "50%",
              outline: "none"
            },
            ".cropper-face.cropper-move": {
              opacity: 0
            }
          }}
        />
      )}
      <Grid
        container
        rowSpacing={2}
        columnSpacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          {(showEmpty || !value) && (
            <Box {...getRootProps()}>
              {isLoading ? (
                <CircularProgress size={14} />
              ) : (
                <Avatar
                  size="xl"
                  sx={{
                    borderStyle: "solid",
                    borderColor: theme.palette.grey[200],
                    backgroundColor: theme.palette.background.paper
                  }}
                >
                  <AddIcon
                    sx={{
                      color: theme.palette.grey[200]
                    }}
                  />
                </Avatar>
              )}
              <input {...getInputProps({ name })} />
            </Box>
          )}

          {value && <Preview value={value} onDelete={handleDelete} />}
        </Grid>
        <Grid item>
          <Grid container rowSpacing={0.5}>
            <Grid item>
              <Typography variant="caption">{label}</Typography>
            </Grid>
          </Grid>
          <Grid container rowSpacing={0.5}>
            <Grid item>
              <Button variant="contained" onClick={open}>
                {children ?? I18n.t("upload-image")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog title={cropModalTitle} open={dialogOpened} onClose={closeDialog}>
        <Divider sx={{ pt: 0.75 }} />
        <DialogContent
          sx={{ minWidth: 350, px: 0, pb: 0.5, overflow: "hidden" }}
        >
          <Cropper
            src={image}
            style={{
              height: 400,
              width: "100%",
              display: "inline-block"
            }}
            dragMode="move"
            zoomTo={zoomRatio}
            zoomOnTouch={false}
            zoomOnWheel={false}
            background={false}
            cropBoxResizable={cropBoxResizable}
            viewMode={1}
            initialAspectRatio={initialAspectRatio}
            guides={false}
            ref={cropperRef}
          />
          <Box padding="5px 20px">
            <Slider
              aria-label="zoom"
              min={0.1}
              step={0.1}
              max={1}
              value={zoomRatio}
              onChange={(_e, newValue) => setZoomRatio(newValue)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={closeDialog}>
            {cancelText}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
